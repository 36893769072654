import React, { useEffect, useState } from "react";
import axiosInstance from "../../utils/Axios";
import {
  AddStudentData,
  GetAllBatches,
  NextAdmissionNumber,
} from "../../utils/EndPoints";
import { toast } from "react-toastify";
import Select from "react-select";
import StudentLoader from "../../components/loading/StudentLoader";

const AddStudent = () => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [classData, setClassData] = useState([]);
  const [yearData, setYearData] = useState([]);
  const [divisionData, setDivisionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    name: "",
    phone_no: "",
    school_name: "",
    batch_year: "",
    class_name: "",
    division: "",
    subjects: "",
    email_id: "",
  });

  const [admi, setAdmin] = useState({
    admission_no: "",
  });

  const inputChangeHandler = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const generateNewAdmissionNumber = async () => {
    axiosInstance
      .get(NextAdmissionNumber)
      .then((res) => {
        setAdmin({
          ...form,
          admission_no: res?.data?.next_adm_no,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Adding the employee to the team
  const selectHandler = (selectedOption) => {
    setSelectedOptions(selectedOption);
    const resultString = selectedOption.map((item) => item.label).join(",");

    setForm({
      ...form,
      subjects: resultString,
    });
  };

  // Select option array
  const options = [
    {
      value: "Physics",
      label: "physics",
    },
    {
      value: "Chemistry",
      label: "chemistry",
    },
    {
      value: "Maths",
      label: "maths",
    },
  ];

  const submitHandler = async (e) => {
    e.preventDefault();
    console.log(form, "dat from the form")
    const subForm = {
      name: form?.name,
      admission_no: admi?.admission_no,
      phone_no: form?.phone_no,
      school_name: form?.school_name,
      email_id: form?.email_id,
    };
    setLoading(true);
    try {
      const response = await axiosInstance.post(
        `${AddStudentData}?batch_year=${form?.batch_year}&class_name=${form?.class_name}&division=${form?.division}&subjects=${form?.subjects}`,
        subForm
      );
      if (response?.status === 201) {
        toast.success("Successfully Created");
        window.scrollTo(0, 0);
        setForm({
          name: "",
          phone_no: "",
          school_name: "",
          batch_year: "",
          class_name: "",
          division: "",
          subjects: "",
          email_id: "",
        });
        setSelectedOptions([]);
        generateNewAdmissionNumber();
      }
    } catch (error) {
      console.log(error);
      toast.error(
        error?.response?.data?.message?.phone_no
          ? "student with this phone number already exists."
          : error?.response?.data?.message?.email_id
          ? "student with this email already exists."
          : "Something went wrong"
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    generateNewAdmissionNumber();
    axiosInstance
      .get(GetAllBatches)
      .then((res) => {
        const uniqueClassNames = new Set(
          res?.data?.class_details.map((item) => item.class_name)
        );
        const uniqueYears = new Set(
          res?.data?.class_details.map((item) => item.batch_year)
        );
        const uniqueDivisions = new Set(
          res?.data?.class_details.map((item) => item.division)
        );

        // Convert Sets back to arrays
        const uniqueClassData = [...uniqueClassNames].map((className) => ({
          class_name: className,
        }));
        const uniqueYearData = [...uniqueYears].map((year) => ({
          batch_year: year,
        }));
        const uniqueDivisionData = [...uniqueDivisions].map((division) => ({
          division,
        }));

        setClassData(uniqueClassData);
        setYearData(uniqueYearData);
        setDivisionData(uniqueDivisionData);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="md:px-28">
      <div className="flex items-center w-full justify-center">
        <h1 className="font-semibold text-2xl font-mono">Add New Student</h1>
      </div>

      <div className="mt-10 w-full border border-[#F4881F]/30 rounded-lg  shadow-lg p-5 py-10">
        <form
          onSubmit={submitHandler}
          action=""
          className="w-full flex flex-col gap-5 md:px-10"
        >
          <div className="flex items-start w-full gap-2 md:gap-5">
            <div className="flex flex-col items-start w-full">
              <label htmlFor="" className="mb-2 font-medium">
                Full name*
              </label>
              <input
                type="text"
                name="name"
                onChange={inputChangeHandler}
                value={form?.name}
                required
                className="rounded text-sm w-full border border-[#F4881F]/30 p-2 focus:outline-none"
              />
            </div>

            <div className="flex flex-col items-start w-full">
              <label htmlFor="" className="mb-2 font-medium">
                Phone Number*
              </label>
              <input
                type="text"
                name="phone_no"
                onChange={inputChangeHandler}
                value={form?.phone_no}
                required
                className={`rounded text-sm w-full border border-[#F4881F]/30 p-2 focus:outline-none `}
              />
            </div>
          </div>

          <div className="flex flex-col items-start w-full">
            <label htmlFor="" className="mb-2 font-medium">
              Email*
            </label>
            <input
              type="email"
              name="email_id"
              onChange={inputChangeHandler}
              value={form?.email_id}
              required
              className="rounded text-sm w-full border border-[#F4881F]/30 p-2 focus:outline-none"
            />
          </div>

          <div className="flex items-start w-full gap-2 md:gap-5">
            <div className="flex flex-col items-start w-full">
              <label htmlFor="" className="mb-2 font-medium">
                School Name*
              </label>
              <input
                type="text"
                name="school_name"
                onChange={inputChangeHandler}
                value={form?.school_name}
                required
                className="rounded text-sm w-full border border-[#F4881F]/30 p-2 focus:outline-none"
              />
            </div>
            <div className="flex flex-col items-start w-full">
              <label htmlFor="" className="mb-2 font-medium">
                Admission Number*
              </label>
              <input
                type="text"
                name="admission_no"
                onChange={inputChangeHandler}
                readOnly
                value={admi?.admission_no}
                required
                className="rounded text-sm w-full border border-[#F4881F]/30 p-2 focus:outline-none"
              />
            </div>
          </div>

          <div className="flex items-start w-full gap-2 md:gap-5">
            <div className="flex flex-col items-start w-full">
              <label htmlFor="" className="mb-2 font-medium">
                Class*
              </label>
              <select
                name="class_name"
                onChange={inputChangeHandler}
                value={form?.class_name}
                id=""
                required
                className="rounded text-sm w-full border border-[#F4881F]/30 p-2 focus:outline-none"
              >
                <option value="">Select Class</option>
                {classData.map((items, i) => (
                  <option key={i} value={items?.class_name}>
                    {items?.class_name}
                  </option>
                ))}
              </select>
            </div>

            <div className="flex flex-col items-start w-full">
              <label htmlFor="" className="mb-2 font-medium">
                Division*
              </label>
              <select
                name="division"
                onChange={inputChangeHandler}
                value={form?.division}
                id=""
                required
                className="rounded text-sm w-full border border-[#F4881F]/30 p-2 focus:outline-none"
              >
                <option value="">Select Class</option>

                {divisionData.map((items, i) => (
                  <option key={i} value={items?.division}>
                    {items?.division}
                  </option>
                ))}
              </select>
            </div>

            <div className="flex flex-col items-start w-full">
              <label htmlFor="" className="mb-2 font-medium">
                Year*
              </label>
              <select
                name="batch_year"
                onChange={inputChangeHandler}
                value={form?.batch_year}
                id=""
                required
                className="rounded text-sm w-full border border-[#F4881F]/30 p-2 focus:outline-none"
              >
                <option value="">Select Year</option>
                {yearData.map((items, i) => (
                  <option key={i} value={items?.batch_year}>
                    {items?.batch_year}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex items-start w-full gap-2 md:gap-5">
            <div className="flex flex-col items-start w-full">
              <label htmlFor="" className="mb-2 font-medium">
                Subjects*
              </label>
              <Select
                options={options}
                value={selectedOptions}
                onChange={selectHandler}
                isMulti={true}
                className="rounded text-sm w-full border border-[#F4881F]/30  focus:outline-none"
              />
            </div>
          </div>

          <div>
            <button className="bg-[#F4881F] p-2 text-sm px-5 rounded text-white hover:scale-105 ease-in-out duration-300">
              Add Student
            </button>
          </div>
        </form>
      </div>
      {loading && <StudentLoader />}
    </div>
  );
};

export default AddStudent;
