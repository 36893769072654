import { useState, useEffect } from "react";

const useBatchYears = () => {
  const [years, setYears] = useState([]);

  useEffect(() => {
    const startYear = 2024;
    const currentYear = new Date().getFullYear();
    const yearList = Array.from(
      { length: currentYear - startYear + 1 },
      (_, i) => startYear + i
    );
    setYears(yearList);
  }, []);

  return years;
};

export default useBatchYears;
