import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import Select from "react-select";
import useBatchYears from "../../../hooks/BatchYear";

const EditModal = ({ setModal, submitHandler, data }) => {
  const [selectedOptions, setSelectedOptions] = useState();
  const [form, setForm] = useState({
    id: data?.id,
    class_name: data?.class_name,
    division: data?.division,
    subjects: data?.subjects,
    batch_year: data?.batch_year,
  });
  // Custom hook
  const years = useBatchYears();

  const inputChangeHandler = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const submitFormData = (e) => {
    e.preventDefault();
    submitHandler(form);
  };

  //Adding the employee to the team
  const selectHandler = (selectedOption) => {
    setSelectedOptions(selectedOption);
    const resultString = selectedOption.map((item) => item.label).join(",");

    setForm({
      ...form,
      subjects: resultString,
    });
  };

  // Select option array
  const options = [
    {
      value: "Physics",
      label: "physics",
    },
    {
      value: "Chemistry",
      label: "chemistry",
    },
    {
      value: "Maths",
      label: "maths",
    },
  ];

  return (
    <div className="fixed top-0 left-0 w-full h-screen overflow-auto bg-black/50 flex items-center justify-center z-50 ease-in-out duration-200">
      <div className="relative  shadow-xl bg-white mt-60  md:mt-0 md:w-1/2 rounded-lg  md:p-10 md:px-14 m-5">
        <h1 className="font-bold text-center text-xl text-primary_colors font-mono">
          Edit Batch
        </h1>

        <IoClose
          onClick={() => setModal(false)}
          className="absolute right-3 top-3 rounded-full border border-black cursor-pointer"
        />
        <div className="flex flex-col  w-full mt-5 border border-[#F4881F]/40 p-5 shadow-lg rounded-lg">
          <form
            action=""
            onSubmit={submitFormData}
            className="flex flex-col gap-5 items-center justify-center my-5"
          >
            <div className="flex items-center justify-center gap-3 w-full">
              <div className="flex flex-col items-start w-full">
                <label htmlFor="" className="mb-2 text-sm font-medium">
                  Class Name
                </label>
                <input
                  type="text"
                  name="class_name"
                  value={form?.class_name}
                  onChange={inputChangeHandler}
                  className="p-2 border text-sm w-full rounded focus:outline-none"
                  placeholder="Eg: Pluse Two"
                />
              </div>
              <div className="flex flex-col items-start w-full">
                <label htmlFor="" className="mb-2 text-sm font-medium">
                  Division
                </label>
                <input
                  type="text"
                  name="division"
                  value={form?.division}
                  onChange={inputChangeHandler}
                  placeholder="Eg: B"
                  className="p-2 border text-sm w-full rounded focus:outline-none"
                />
              </div>
            </div>

            <div className="flex items-center justify-center gap-3 w-full">
              <div className="flex flex-col items-start w-full">
                <label htmlFor="" className="mb-2 text-sm font-medium">
                  Subject
                </label>
                <Select
                  options={options}
                  value={selectedOptions}
                  onChange={selectHandler}
                  isMulti={true}
                  defaultInputValue={form?.subjects}
                  className="rounded text-sm w-full border  focus:outline-none"
                />
              </div>
              <div className="flex flex-col items-start w-full">
                <label htmlFor="" className="mb-2 text-sm font-medium">
                  Batch Year
                </label>
                <select
                  type="text"
                  name="batch_year"
                  required
                  placeholder="Eg: 2023"
                  onChange={inputChangeHandler}
                  className="p-2 border text-sm w-full rounded focus:outline-none"
                >
                  <option value="">Select A Option</option>
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="w-full flex items-center justify-center py-2 gap-3">
              <button
                type="submit"
                className="bg-[#F4881F]  p-2 px-5 rounded text-white hover:scale-105 ease-in-out duration-200"
              >
                Submit
              </button>
              <button
                onClick={() => setModal(false)}
                type="button"
                className="bg-red-500  p-2 px-5 rounded text-white hover:scale-105 ease-in-out duration-200"
              >
                cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditModal;
