import React, { useEffect, useState } from "react";
import { LuCopyPlus } from "react-icons/lu";
import BatchCard from "../../components/batch/BatchCard";
import { toast } from "react-toastify";
import axiosInstance from "../../utils/Axios";
import { AddABatch, GetAlBatchWithFilter, GetAllBatches } from "../../utils/EndPoints";
import CreateBatchModal from "../../components/batch/modal/CreateBatchModal";
import StudentLoader from "../../components/loading/StudentLoader";
import useBatchYears from "../../hooks/BatchYear";

const currentYear = new Date().getFullYear();

const Batch = () => {
  const [data, setData] = useState([]);
  const [batchModal, setBatchModal] = useState(false);
  const [submitModal, setSubmitModal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [year, setYear] = useState(currentYear);

  console.log(currentYear, "current year before data")

  //year listing hooking
  const years = useBatchYears();

  const batchData = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`${GetAlBatchWithFilter}?year=${year}`);
      setData(response?.data?.class_details);
    } catch (error) {
      console.log(error);
      toast.error("Something Went Wrong");
    } finally {
      setLoading(false);
    }
  };

  const createBatch = async () => {
    console.log(submitModal)
    if (submitModal != null) {
      setLoading(true);
      try {
        const response = await axiosInstance.post(AddABatch, submitModal);
        console.log(response);
        if (response?.status === 201) {
          toast.success(response?.data?.message);
          setBatchModal(false);
          setSubmitModal(null);
          batchData();
        }
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.Message || "Something Went Wrong");
      } finally {
        setLoading(false);
      }
    }
  };

  const ChangeBatchYear = (e) => {
    setYear(e.target.value);
  }

  console.log(currentYear, "current year after data");

  useEffect(() => {
    window.scrollTo(0, 0);
    batchData();
    createBatch();
  }, [year, submitModal]);

  return (
    <>
      <div className="w-full min-h-screen md:px-10">
        <div className="flex flex-col lg:flex-row items-center justify-between">
          <h1 className="font-semibold text-2xl md:text-2xl font-mono">
            Batch
          </h1>
          <div className="flex gap-5 flex-col lg:flex-row mt-5 lg:mt-0 w-full lg:w-fit items-center justify-center">

            <select
              type="text"
              name="batch_year"
              required
              placeholder="Eg: 2023"
              onChange={ChangeBatchYear}
              className="p-2 border text-sm w-full rounded focus:outline-none"
            >
              <option value="">Select A Option</option>
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>

            <button
              onClick={() => setBatchModal(true)}
              className="bg-[#F4881F] w-full text-nowrap text-white text-sm font-semibold p-2  px-8  flex items-center justify-center gap-2 rounded hover:scale-105 ease-in-out duration-300"
            >
              <LuCopyPlus size={20} />
              Create A Batch
            </button>

          </div>

        </div>

        <div className="flex flex-wrap items-start justify-start mt-10 gap-10">
          {data.map((items) => (
            <BatchCard key={items?.id} data={items} batchData={batchData} />
          ))}
        </div>
      </div >
      {batchModal && (
        <CreateBatchModal
          data={data}
          setModal={setBatchModal}
          submitHandler={setSubmitModal}
        />
      )
      }
      {loading && <StudentLoader />}
    </>
  );
};

export default Batch;
